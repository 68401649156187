.carousel-container {
  display: flex;
  flex-direction: column;
  padding: 48px 0;
  border: 1px solid #d8d8dc;
  border-radius: 6px;
  // @include sm {
  width: 720px;
  height: 400px;

  // }
  .slider-container {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    overflow: hidden;

    .nav-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      cursor: pointer;

      &.disabled {
        opacity: 0.25;
      }
    }

    .carousel-content-container {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .carousel-title {
        margin: 0 5px;
        font-size: 18px;
        font-weight: bold;
      }

      .carousel-slide-container {
        // @include sm {
        width: 600px;
        // }
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        scroll-behavior: smooth;

        .carousel-card-container {
          padding: 0 5px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .carousel-card {
            display: flex;
            align-items: center;
            justify-content: center;
            vertical-align: center;
            font-weight: bold;
            color: white;
            background-color: #55adff;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.carousel-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}