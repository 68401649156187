@import "colors";

$body-color: $primary;

$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

@mixin boxContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 40px;
    width: 90%;
    height: auto;
    border: 1px solid #D8D8DC;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03), 0 2px 6px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    @content;
}



@mixin textContainer {
    width: 100%;
    height: auto;
    padding: 0 12px 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }

 // Medium devices
 @mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }

 // Large devices
 @mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
 }

 // Extra large devices
 @mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
 }

 @mixin selector-title {
    .selector-title {
        display: flex;
        background-color: #f2f2f2;
        padding: 0.5rem;
        font-size: 14px;
        font-weight: 700;
    }
 }
