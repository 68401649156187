.author-item {
    .author-header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .author-image,
        .author-preferred-book {
            margin-bottom: 10px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03), 0 2px 6px rgba(0, 0, 0, 0.03);
            border-radius: 5px;
        }

        .author-preferred-book {
            margin-left: 5px;
        }
    }

    .author-name {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
    }

    .author-description {
        font-size: 12px;
        color: #494949;
    }
}