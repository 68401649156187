@font-face {
  font-family: 'GT America';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('fonts/GT-America-Standard-Regular.woff2');
}
@font-face {
  font-family: 'GT America';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('fonts/GT-America-Standard-Regular-Italic.woff2');
}
@font-face {
  font-family: 'GT America';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('fonts/GT-America-Standard-Medium.woff2');
}
@font-face {
  font-family: 'GT America';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('fonts/GT-America-Standard-Bold.woff2');
}
@font-face {
  font-family: 'GT America';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('fonts/GT-America-Standard-Bold.woff2');
}
