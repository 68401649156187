input {
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 7.5px 11px;
    outline: none;
    border: 1px solid #e8e8e8;
    border-radius: 4px;

    &.active {
        border: 1px solid #55adff;
    }

    &::-webkit-input-placeholder {
        /* Edge */
        color: #9c9c9c;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer */
        color: #9c9c9c;
    }

    &::placeholder {
        color: #9c9c9c;
    }
}