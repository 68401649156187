@import "variables";

.showcase-group-container {
    @include boxContainer {
        gap: 36px;
    }

    .horizontal-showcase-container {
        display: flex;
        gap: 20px;
        width: 100%;
        overflow-x: scroll;
        padding: 1px 2px 10px 1px;
    }

    .image-pill-container {
        min-width: 176px;
        height: 80px;
        background: #E2F1FA;
        border: 0.469974px dashed #AAD5EE;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .image2 {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;

        }
    }

    .cover-pill-container {
        background: #FFFFFF;
        box-shadow: 0 0.803949px 4.01975px #DBDBDB;
        border-radius: 2.41185px;
        display: flex;
        padding: 8px;
        width: 176px;
        height: 80px;
        gap: 15px;
        align-items: center;


        .images-container {
            display: flex;
            gap: 0;

            .image-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0;
                gap: 4.2px;
                width: 30.67px;
                height: 64px;
                background: #E2F1FA;
                border: 0.630192px dashed #AAD5EE;
                border-radius: 0 1.68051px 1.68051px 0;
            }
        }
    }

    .collage-panel-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        gap: 16px;

        width: 196px;
        height: auto;

        background: #FFFFFF;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

        .single-image {
            width: 100%;
            height: 185px;
        }

        .collage-images {
            display: grid;
            grid-template-columns: auto auto auto auto;
            grid-template-rows: auto auto;
            width: 100%;
            height: 193px;

            .image-container {
                width: 49px;
                height: 96.5px;
                background: #E2F1FA;
                border: 0.630192px dashed #AAD5EE;
                border-radius: 1.68051px;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }

        .text-container {
            @include textContainer
        }

    }

    .image-panel-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 196px;
        height: auto;

        background: #FFFFFF;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

        .image-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0;

            width: 196px;
            min-height: 185px;

            background: #E2F1FA;
            border: 0.630192px dashed #AAD5EE;
            border-radius: 1.68051px;
        }

        .text-container {
            @include textContainer
        }
    }

}
