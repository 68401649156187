.upload-image-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .dropdown {
        width: 100%;
        height: auto;

        /* White */

        background: #FFFFFF;
        /* Dropdown Menu Shadow */

        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.07), 0 4px 10px rgba(0, 0, 0, 0.03);
        border-radius: 4px;

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;

        .color-container {
            display: flex;
            justify-content: space-between;
            padding: 8px 41px 8px 15px;
            align-items: center;
            background-color: #FFFFFF;
            cursor: pointer;

            .color {
                width: 120px;
                height: 42px;
            }
        }

        .color-container:hover {
            background-color: #F2F2F2;
        }
    }

    .overlay-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        gap: 16px;
        width: 100%;
        height: 58px;
        background: #FFFFFF;
        border: 1px solid #D8D8DC;
        border-radius: 4px;
        cursor: pointer;

        .arrow-button-active {
            transform: rotateX(180deg);
        }
    }

    .active {
        border: 1px solid #0075E3;
    }

    .upload-image-button {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
        width: 100%;
        height: 116px;
        background: #FFFFFF;
        border: 0.630192px dashed #BCBCBC;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        cursor: pointer;
    }

    .preview {
        width: 120px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .img {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
        }
    }

    .image-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
        width: 100%;
        height: 116px;
        border: 0.630192px dashed #BCBCBC;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        position: relative;

        .overlay {
            width: 100%;
            height: 100%;
            opacity: 0.6;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 4px
        }

        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;

        }
    }
}
