.checkbox-input {
    position: relative;
    display: block;
    height: 18px;
    width: 18px;
    border: 1px solid #6c6c6c;
    box-sizing: border-box;
    flex-shrink: 0;
    padding: 10px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 18px;
        width: 18px;

        &:checked {
            +.checkmark {
                background-color: #0075e3;

                &:after {
                    display: block;
                }
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        z-index: -1;

        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 4px;
            left: 4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: white;
        }
    }
}