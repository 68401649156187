.switch-button {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 16px;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked {
            +.slider {
                background-color: #15A572;
            }

            +.slider::before {
                -webkit-transform: translateX(8px);
                -ms-transform: translateX(8px);
                transform: translateX(8px);
            }
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 24px;

        &::before {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }
    }
}