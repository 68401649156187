.type-ahead-container {
    display: flex;
    width: 100%;
    max-width: 560px;
    font-size: 14px;
    height: 33px;

    .search-icon-container {
        height: 100%;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-left: 1px solid #e8e8e8;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        border-radius: 4px 0 0 4px;

        &.active {
            border-left: 1px solid #55adff;
            border-top: 1px solid #55adff;
            border-bottom: 1px solid #55adff;
        }
    }

    .type-ahead-dropdown {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        input {
            border-left: none;
            border-radius: 0 4px 4px 0;
            height: 100%;

            &.active {
                border-left: none;
                border-radius: 0 4px 4px 0;
            }
        }

        .action-button {
            width: 100%;
            background-color: white;
            list-style-type: none;
            border-left: 1px solid #e8e8e8;
            border-right: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
            border-radius: 0 0 4px 4px;
            z-index: 100;

            &:hover {
                background-color: #e8e8e8;
                cursor: pointer;
            }

            padding: 8px 4px;
            text-align: center;
            font-weight: bold;
            color: #55adff;
        }

        ul {
            width: 100%;
            background-color: white;
            list-style-type: none;
            border-left: 1px solid #e8e8e8;
            border-right: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
            border-radius: 0 0 4px 4px;
            z-index: 100;

            li {
                padding: 8px 4px;

                &:hover {
                    background-color: #e8e8e8;
                    cursor: pointer;
                }

                &.action-button {
                    text-align: center;
                    font-weight: bold;
                    color: #55adff;
                }
            }
        }
    }

    &.large {
        .search-icon-container {
            height: 50px;
            background-color: #bdbdbd;
        }

        .type-ahead-dropdown {
            input {
                padding: 16px 0 16px 16px;
            }
        }
    }
}
