.radio-button {
  position: relative;
  display: block;
  height: 18px;
  width: 18px;
  border: 1px solid #6C6C6C;
  box-sizing: border-box;
  border-radius: 50%;
  flex-shrink: 0;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 18px;
    width: 18px;

    &:checked {
      +.checkmark {
        background-color: #0075E3;

        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    z-index: -1;

    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }
}