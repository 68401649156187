.showcase-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 13px;
  gap: 8px;

  width: 262px;
  height: 40px;

  /* Blue/500 */

  background: #0075E3;
  /* Blue Shadow */

  box-shadow: 0 1px 6px rgba(0, 134, 255, 0.14), 0 1px 3px rgba(0, 134, 255, 0.03);
  border-radius: 4px;
  cursor: pointer;
}
