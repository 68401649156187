.events-item {
    display: flex;
    border: 1px solid;
    border-radius: 5px;
    color: white;
    position: relative;

    .event-item-background {
        background: #0000007a;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .events-item-date {
        z-index: 1;
        padding: 30px 20px 30px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .events-item-day {
            font-weight: 500;
            font-size: 31.6074px;
            line-height: 37px;
        }

        .events-item-month {
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            text-align: center;
        }
    }

    .events-item-content {
        z-index: 1;
        padding: 10px;
    }
}