.modal-dialog {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    color: var(--colour-secondary);
    font-family: "Work Sans", sans-serif;

    .backdrop {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background: #000000;
        opacity: 0.4;
    }

    .modal {
        width: auto;
        max-height: calc(100vh - 100px);
        position: relative;
        display: flex;
        flex-flow: column;
        z-index: 20;
        min-height: 100px;
        background: white !important;
        border-radius: 24px;

        .title {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            line-height: 64px;
            border-bottom: 1px solid #e7edf4;
        }

        .content {
            overflow-x: hidden;
            overflow-y: auto;
        }

        .contentPadding {
            padding: 0 20px;
        }

        .error {
            padding: 10px 20px;
            font-size: 14px;
            color: #d70505;
            font-weight: 600;
            border-top: 1px solid #e7edf4;
            text-align: center;
        }

        .actions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 17px 20px 15px;
            border-top: 1px solid #e7edf4;
            background: #fbfcfd;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .close-icon {
        position: absolute;
        right: 8px;
        cursor: pointer;
        padding-top: 12px;
        padding-right: 4px;
    }
}

.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    z-index: 11;
}
