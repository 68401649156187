.multistep-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .step-title-wrapper {
        display: flex;
        align-items: center;
    }
    .step-title-container {
        display: flex;
        align-items: center;

        &.step-active {
            .step {
                border: 1px solid #0075e3;
                color: #0075e3;
            }
            .title {
                color: #0075e3;
            }
        }
        &.step-completed {
            .step {
                border: 1px solid #0075e3;
                color: #0075e3;
                background-color: #0075e3;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .title {
                color: #0075e3;
            }
        }

        .step {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 1px solid #9c9c9c;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #9c9c9c;
            font-size: 12px;
        }

        .title {
            margin-left: 10px;
            font-weight: 700;
            color: #9c9c9c;
            font-size: 14px;
        }

        .title-active,
        .title-completed {
            color: #0075e3;
        }
    }

    .line {
        width: 95px;
        height: 1px;
        background-color: #cdcdcd;
        margin-left: 50px;
        margin-right: 50px;
    }
    .active-line {
        background-color: #0075e3;
    }
}
