.search-modal-container {
    width: 1120px;
    height: 740px;
    display: flex;

    .modal-left-section {
        width: 20%;
        height: 100%;
        border-right: 1px solid #d9d9d9;
        padding-top: 8%;
        padding-bottom: 4%;

        .modal-left-section-content {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            .content-container {
                padding-bottom: 20px;
                padding-top: 20px;
                padding-left: 25%;

                .category {
                    padding-left: 18px;
                    font-size: 14px;
                    font-weight: 700;
                }

                .checkbox-container {
                    display: flex;
                    flex-direction: column;
                    padding-top: 10px;

                    p {
                        font-size: 14px;
                    }
                }
            }
        }

        .border-bottom {
            border-bottom: 1px solid #d9d9d9;
        }
    }

    .modal-right-section {
        width: 80%;
        height: 100%;
        padding-top: 4%;
        display: flex;
        flex-direction: column;

        .modal-left-section-search {
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .modal-left-section-result {
            width: 100%;
            min-height: 40%;
            padding-top: 6%;
            padding-left: 60px;
            padding-right: 30px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 0;
            grid-row-gap: 8px;
            overflow-y: scroll;

            p {
                font-size: 14px;
                font-weight: 400;
            }
        }

        .modal-left-section-pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20%;
            margin-top: 280px;
        }
    }
}
