/* total width */
::-webkit-scrollbar {
    background:#fff;
    width:8px;
    height:8px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background:#fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background:#F2F2F2;
    border-radius:16px;

    &:hover {
        background-color: #CDCDCD;
    }
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {display:none}